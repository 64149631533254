import Vue from "vue";
const COMMENTS_PER_PAGE = 10;
const comments = {
    namespaced: true,
    state: {
        loaded: false,
        list: {
            // array of comments and loaded status for idea with id == 0
            i0: {
                comments: [],
                loaded: true,
                page: 1
            }
        }
    },
    getters: {
        ideaCommentsList: state => ideaId =>
            state.list["i" + ideaId] ? state.list["i" + ideaId].comments : [],
        ideaCommentsLoaded: state => ideaId =>
            state.list["i" + ideaId] ? state.list["i" + ideaId].loaded : true,
        ideaComments: state => ideaId => state.list["i" + ideaId]
    },
    mutations: {
        SET_LOADED_STATUS(state, { ideaId, status }) {
            if (!state.list["i" + ideaId]) {
                state.list["i" + ideaId] = Object.assign({}, state.list.i0);
            }
            state.list["i" + ideaId].loaded = status;
        },
        PUSH_COMMENTS(state, { ideaId, comments, unshift = false }) {
            const newIdeaObj = JSON.parse(JSON.stringify(state.list["i" + ideaId]));
            if (unshift) {
                newIdeaObj.comments.unshift(...comments);
            } else {
                newIdeaObj.comments.push(...comments);
            }
            Vue.set(state.list, `i${ideaId}`, newIdeaObj);
        },
        ADD_CHILD_COMMENT(state, { comment, rootId }) {
            const ideaId = comment.idea_id;
            const rootComment = state.list["i" + ideaId].comments.find(
                comment => comment.id === rootId
            );
            if (rootComment) {
                rootComment.children.unshift(comment);
            }
        },

        DELETE_ROOT_COMMENT(state, { ideaId, commentId }) {
            state.list["i" + ideaId].comments = state.list["i" + ideaId].comments.filter(
                comment => comment.id !== commentId
            );
        },
        DELETE_CHILD_COMMENTS(state, { ideaId, rootId, commentsId }) {
            const rootComment = state.list["i" + ideaId].comments.find(
                comment => comment.id === rootId
            );
            rootComment.children = rootComment.children.filter(
                child => !commentsId.includes(child.id)
            );
        },
        INCREMENT_PAGE(state, ideaId) {
            state.list["i" + ideaId].page++;
        }
    },
    actions: {
        loadForIdea({ state, commit }, ideaId) {
            commit("SET_LOADED_STATUS", { ideaId, status: false });
            const requestData = {
                ideaId: ideaId,
                page: state.list["i" + ideaId] ? state.list["i" + ideaId].page : 1,
                perPage: COMMENTS_PER_PAGE
            };
            return new Promise((resolve, reject) => {
                this.$app.$api.comments
                    .getIdeaComments(requestData)
                    .then(({ data }) => {
                        commit("PUSH_COMMENTS", { ideaId, comments: data.data });
                        commit("INCREMENT_PAGE", ideaId);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
                    .finally(() => {
                        commit("SET_LOADED_STATUS", { ideaId, status: true });
                    });
            });
        },
        saveComment({ commit }, { entityId, commentData }) {
            let ideaId = entityId;
            let request = this.$app.$api.comments.saveComment(ideaId, commentData);
            request.then(response => {
                const comment = response.data.data;
                if (!comment.parent_id) {
                    commit("PUSH_COMMENTS", {
                        ideaId: comment.idea_id,
                        comments: [comment],
                        unshift: true
                    });
                } else {
                    commit("ADD_CHILD_COMMENT", { comment, rootId: commentData.root_id });
                }
                commit(
                    "idea/INCREMENT_COMMENT",
                    { ideaId: comment.idea_id, isRoot: comment.parent_id == null },
                    { root: true }
                );
            });
            return request;
        },
        deleteComment({ commit }, comment) {
            const childrenIds = comment.children.map(child => child.id);
            let request = this.$app.$api.comments.deleteComment(comment.id);
            request.then(() => {
                commit(
                    "idea/DECREMENT_COMMENTS",
                    {
                        ideaId: comment.idea_id,
                        isRoot: comment.parent_id != null,
                        children: childrenIds.length
                    },
                    { root: true }
                );
                if (comment.id === comment.root_id) {
                    commit("DELETE_ROOT_COMMENT", {
                        ideaId: comment.idea_id,
                        commentId: comment.id
                    });
                } else {
                    commit("DELETE_CHILD_COMMENTS", {
                        ideaId: comment.idea_id,
                        rootId: comment.root_id,
                        commentsId: childrenIds.concat([comment.id])
                    });
                }
            });
            return request;
        }
    }
};

export default comments;
