export default {
    views: {
        idea: {
            index: "Have an idea",
            show: "Idea card",
            edit: "Edit the idea",
            create: "Create an idea"
        },
        category: {
            categories: "Categories",
            edit: "Edit the category",
            create: "Create a category",
            show: "Category",
        },
        help: "Help"
    },
    index: {
        title: "Have an idea",
        preview: "Welcome to HiHub Idea service"
    },

    statuses: {
        new: "new",
        in_progress: "in progress",
        implemented: "implemented",
        rejected: "rejected"
    },
    user: {
        user: "User"
    },
    errors: {
        server_error: "Internal server error",
        required_field: "This field is required",
        at_least_3: "This field is required and the number of characters is at least 3",
        empty_tag: "This tag does not have attached ideas"
    },
    placeholders: {
        start_typing: "Start typing",
        input_text: "Input text here",
        idea_heading: "Enter a Heading",
        enter_description: "Enter the description here",
        enter_title: "Enter the title here",
        enter_preview: "Enter the preview here",
        enter_category_title: "Enter category name",
        enter_category_description: "Enter category description",
        enter_category_parent: "Select a parent category"
    },

    common: {
        action: "Action",
        create: "Create",
        created: "Created",
        delete: "Delete",
        deleting: "Deleting",
        edit: "Edit",
        load_more: "Load more",
        ok: "OK",
        cancel: "Cancel",
        confirm: "Are you sure that you want to delete",
        progress: "Progress",
        publish: "Publish",
        save: "Save",
        show_more: "Show more",
        title: "Title",
        tag: "Tag",
        updated: "Updated"
    },
    category: {
        categories: "Categories",
        category: "Category",
        confirm: "Are you sure that you want to delete the category",
        create: "Create a category",
        delete: "Deleting the category",
        edit: "Edit category",
        choose_parent: "Choose Parent Category",
        no_ideas: "This category does not contain idea(s)",
        no_category: "No category",
        no_found: "Category not found",
        name: "Enter the name of the category",
        parent: "Parent category",
        description: "Category description",
        has_no_ideas: "No ideas found in this category"
    },
    idea: {
        author: "Author",
        category: "Select a category",
        confirm: "Are you sure that you want to delete the idea",
        create: "Create an idea",
        comments: "Comments",
        delete: "Deleting the idea",
        edit: "Edit idea",
        have_an_idea: "Have an Idea",
        heading: "Heading",
        ideas: "Ideas",
        like: "Like",
        likes: "Likes",
        unlike: "Remove like",
        status: "Select status",
        tags: "Tags",
        title: "Idea title",
        updated: "Last updated",
        preview: "Preview",
        switch: {
            public: "Public idea",
            anon: "Anonymous idea"
        },
        anon: "Anonymous",
    },
    favourites: {
        favourites: "favourites",
        empty: "Favorite list is empty",
        pages: "Pages"
    },
    notifies: {
        comment_success: "You added a comment!",
        comment_fail: "Comment has not been added",
        idea_created: "You have created an idea!",
        idea_create_failed: "Failed to create idea",
        idea_edited: "Idea has been edited!",
        idea_edit_failed: "Failed to edit idea",
        category_created: "You have created a category!",
        category_create_failed: "Failed to create category",
        category_edited: "Category has been edited!",
        category_edit_failed: "Failed to edit category",
        title_empty_index_page: "Be the first to share an idea in this space",
        title_empty_categories: "There are no created categories yet"
    },
    froala: {
        content_placeholder: "Enter text here",
        text: {
            code: "Code",
            highlighted: "Highlighted",
            transparent: "Transparent"
        },
        paragraph: {
            grey: "Grey",
            bordered: "Bordered",
            spaced: "Spaced",
            uppercase: "Uppercase"
        },
        link_styles: {
            green: "Green",
            strong: "Strong"
        }
    },
};
