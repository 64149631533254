export default {
    views: {
        idea: {
            index: "Есть идея",
            show: "Карточка идеи",
            edit: "Редактирование идеи",
            create: "Создание идеи"
        },
        category: {
            index: "Категории",
            edit: "Редактирование категории",
            create: "Создание категории",
            show: "Категория",
        },
        help: "Помощь"
    },
    index: {
        title: "Есть идея",
        preview: "Добро пожаловать в сервис HiHub "
    },
    statuses: {
        new: "новая",
        in_progress: "в работе",
        implemented: "внедрена",
        rejected: "отклонена"
    },
    user: {
        user: "Пользователь"
    },
    errors: {
        server_error: "Ошибка на сервере",
        required_field: "Это поле обязательно к заполнению",
        at_least_3: "Данное поле обязательно к заполнению и должно содержать не менее 3-х символов",
        empty_tag: "К данному тэгу не привязаны идеи"
    },
    placeholders: {
        start_typing: "Начните писать",
        input_text: "Наберите текст",
        idea_heading: "Введите заголовок",
        enter_description: "Напишите описание здесь",
        enter_title: "Введите название идеи",
        enter_preview: "Введите краткое описание",
        enter_category_title: "Введите название категории",
        enter_category_description: "Введите описание категории",
        enter_category_parent: "Выберите родительскую категорию"
    },
    common: {
        action: "Действие",
        create: "Создать",
        created: "Дата создания",
        delete: "Удалить",
        deleting: "Удаление",
        edit: "Редактировать",
        load_more: "Загрузить ещё",
        ok: "OK",
        cancel: "Отменить",
        confirm: "Вы уверены, что хотите удалить",
        progress: "Прогресс",
        publish: "Опубликовать",
        save: "Сохранить",
        show_more: "Показать ещё",
        title: "Название",
        tag: "Тэг",
        updated: "Дата обновления"
    },
    category: {
        categories: "Категории",
        category: "Категория",
        confirm: "Вы уверены, что хотите удалить категорию",
        create: "Создать категорию",
        delete: "Удаление категории",
        edit: "Редактировать категорию",
        choose_parent: "Выберите родительскую категорию",
        no_ideas: "Данная категория не содержит идей",
        no_category: "Без категории",
        no_found: "Категория не найдена",
        name: "Введите название категории",
        parent: "Родительская категория",
        description: "Описание категории",
        has_no_ideas: "Не найдено идей в данной категории"
    },
    idea: {
        author: "Автор",
        category: "Выберите категорию",
        confirm: "Вы уверены, что хотите удалить идею",
        create: "Создать идею",
        comments: "Комментарии",
        description: "Описание идеи",
        delete: "Удаление идеи",
        edit: "Редактировать идею",
        have_an_idea: "Есть идея",
        heading: "Заголовок",
        ideas: "Идеи",
        like: "Нравится",
        likes: "Лайки",
        unlike: "Не нравится",
        status: "Статус",
        tags: "Тэги",
        title: "Название идеи",
        updated: "Дата обновления",
        preview: "Краткое описание",
        switch: {
            public: "Публичная идея",
            anon: "Анонимная идея"
        },
        anon: "Аноним",
    },
    favourites: {
        favourites: "избранное",
        empty: "Список избранного пуст",
        pages: "Страниц"
    },
    notifies: {
        comment_success: "Вы добавили комментарий!",
        comment_fail: "Комментарий не был добавлен",
        idea_created: "Вы создали идею!",
        idea_create_failed: "Не удалось создать идею",
        idea_edited: "Идея была отредактирована!",
        idea_edit_failed: "Не удалось отредактировать идею",
        category_created: "Вы создали категорию!",
        category_create_failed: "Не удалось создать категорию",
        category_edited: "Категория была отредактирована!",
        category_edit_failed: "Не удалось отредактировать категорию",
        title_empty_index_page: "Будьте первым, кто поделится идеей в этом пространстве",
        title_empty_categories: "Созданных категорий еще нет"
    },
    froala: {
        content_placeholder: "Введите текст здесь",
        text: {
            code: "Код",
            highlighted: "Подсвеченный",
            transparent: "Прозрачный",
        },
        paragraph: {
            grey: "Серый",
            bordered: "Обрамлённый",
            spaced: "С пробелами",
            uppercase: "Заглавный"
        },
        link_styles: {
            green: "Зелёный",
            strong: "Жирный"
        }
    },
};
