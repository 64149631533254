export default {
    subheader: {
        breadcrumbs: {
            display: true
        }
    },
    content: {
        width: "fluid"
    }
};
