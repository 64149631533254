// import store from "@/core/services/store";

const loadMenuChildren = function(id) {
    return new Promise(resolve => {
        this.$api.ideaMenu
            .loadChildrenOptions(id)
            .then(response => {
                const list = [];
                response.data.data.forEach(cat => {
                    list.push(createCategoryOption(cat));
                });
                resolve(list);
            })
            .catch(() => {
                resolve(null);
            });
    });
};

const loadAncestorsTree = function(entity_id) {
    return new Promise((resolve, reject) => {
        if (itemExistsOnMenu.call(this, entity_id)) {
            this.$store.commit("menu/CLOSE_ALL");
            resolve();
        } else {
            this.$api.ideaMenu
                .buildAncestorsTree(entity_id)
                .then(response => {
                    let base_route = "aside.categories";
                    response.data.forEach(level => {
                        if (level[0].parent_id) base_route += ".cat" + level[0].parent_id;
                        const list = [];

                        level.forEach(cat => {
                            list.push(createCategoryOption(cat));
                        });
                        const payload = {
                            subMenu: list,
                            code: base_route
                        };
                        this.$store.dispatch("menu/addSubmenu", payload);
                    });
                    resolve();
                })
                .catch(() => {
                    reject(new Error());
                });
        }
    });
};

const itemExistsOnMenu = function(entity_id) {
    const loadedOptions = Object.keys(this.$store.getters["menu/list"]);
    const prefix = "cat";
    const itemLastCode = prefix + entity_id;
    let flag = false;
    loadedOptions.forEach(category => {
        const arr = category.split(".");
        if (arr[arr.length - 1] === itemLastCode) flag = true;
    });
    return flag;
};

const createCategoryOption = category => {
    return {
        type: "link",
        to: {
            name: "category.show",
            params: { id: category.id }
        },
        icon: "flaticon2-files-and-folders", // not required
        active: true,
        text: {
            default: category.name
        },
        submenu: [],
        loader: function() {
            return loadMenuChildren.call(this, category.id);
        },
        code: "cat" + category.id,
        loaded: !category.has_children
    };
};

export { createCategoryOption, loadAncestorsTree };
export default {
    // Root level also must be configured as menu item (must have submenu key, other ignored)
    submenu: {
        ideas: {
            active: true,
            to: {
                name: "idea.index",
                params: {}
            },
            text: {
                code: "idea.ideas",
                default: "Ideas"
            },
            b_icon: "lightbulb",
        },
        categories: {
            to: {
                // not required, recommended (vue route-link to parameter)
                name: "category.index"
            },
            loader: function() {
                return loadMenuChildren.call(this, 0);
            },
            loaded: false,
            icon: "flaticon2-files-and-folders", // not required
            text: {
                code: "views.category.index",
                default: "Categories"
            },
            submenu: []
        },
        help: {
            active: true,
            to: {
                name: "help",
                params: {},
            },
            text: {
              code: "views.help",
              default: 'Help',
            },
            b_icon: "question-circle",
        },
    }
};
