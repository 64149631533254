export default class ApiRequests {}

ApiRequests.getTreeChildrenOf = function({ client }, section_id = 0) {
    if (section_id > 0) {
        return client.get("/api/idea/tree/children/" + section_id);
    } else {
        return client.get("/api/idea/tree/children");
    }
};

ApiRequests.getFullTree = function({ client }) {
    return client.get("/api/idea/tree/full");
};

ApiRequests.comments = {
    getIdeaComments: ({ client }, { ideaId, page, perPage }) =>
        client.get("/api/idea/" + ideaId + "/comments?page=" + page + "&per=" + perPage),
    saveComment: ({ client }, ideaId, comment) =>
        client.post("/api/idea/" + ideaId + "/comment/", comment),
    deleteComment: ({ client }, id) => client.delete("/api/idea/comment/" + id)
};

ApiRequests.saveIdea = function({ client }, idea) {
    if (idea.id) {
        return client.patch("/api/idea/idea/" + idea.id, idea);
    } else {
        return client.post("/api/idea/idea/", idea);
    }
};

ApiRequests.saveCategory = function({ client }, category) {
    if (category.id) {
        return client.patch("/api/idea/category/" + category.id, category);
    } else {
        return client.post("/api/idea/category/", category);
    }
};

ApiRequests.getFullTreeCategories = function({ client }) {
    return client.get("/api/idea/tree/fullcategories");
};

ApiRequests.getCategoriesList = function({ client }) {
    return client.get("/api/idea/category");
};

ApiRequests.getCategory = function({ client }, id) {
    return client.get("/api/idea/category/" + id);
};

ApiRequests.getIdea = function({ client }, id) {
    return client.get("/api/idea/idea/" + id);
};

ApiRequests.getStatus = function({ client }, id) {
    return client.get("/api/idea/status/" + id);
};
ApiRequests.getStatuses = function({ client }) {
    return client.get("/api/idea/status/");
};

ApiRequests.deleteCategory = function({ client }, id) {
    return client.delete("/api/idea/category/" + id);
};

ApiRequests.deleteIdea = function({ client }, id) {
    return client.delete("/api/idea/idea/" + id);
};

ApiRequests.loadIdeas = function({ client }, data) {
    return client.get(
        "/api/idea/list/" + data.categoryId + "/" + data.userId + "?page=" + data.page
    );
};
ApiRequests.ideaMenu = {
    loadChildrenOptions: ({ client }, id) => client.get("/api/idea/category/load-for-menu/" + id),
    buildAncestorsTree: ({ client }, id) =>
        client.get("/api/idea/category/build-ancestors-tree/" + id)
};

ApiRequests.reactions = {
    likeIdea: ({ client }, ideaId) => client.post("/api/idea/idea/like/" + ideaId),
    unLikeIdea: ({ client }, ideaId) => client.post("/api/idea/idea/unlike/" + ideaId)
};
ApiRequests.ideaSearch = {
    full: ({ client }, search) =>
        client.get(encodeURI("/api/idea/search?per_page=10&search=" + search))
};

