import idea from "./idea";
import category from "./category";
import comments from "./comments";

export default [
    {
        name: "idea",
        module: idea
    },
    {
        name: "category",
        module: category
    },
    {
        name: "comments",
        module: comments
    }
];
