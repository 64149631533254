export default {
    views: {
        idea: {
            index: "Habe eine Idee",
            show: "Ideenkarte",
            edit: "Idee bearbeiten",
            create: "Erstelle eine Idee"
        },
        category: {
            categories: "Kategorien",
            edit: "Kategorie bearbeiten",
            create: "Erstelle eine Kategorie",
            show: "Kategorie",
        },
        help: "Hilfe"
    },
    index: {
        title: "Habe eine Idee",
        preview: "Willkommen bei HiHub Services"
    },
    statuses: {
        new: "neu",
        in_progress: "bei der Arbeit",
        implemented: "implementiert",
        rejected: "abgelehnt"
    },
    user: {
        user: "Benutzer"
    },
    errors: {
        server_error: "Fehler auf dem Server",
        required_field: "Dieses Feld muss ausgefüllt sein",
        at_least_3: "Dieses Feld muss ausgefüllt sein und muss mindestens 3 Zeichen enthalten.",
        empty_tag: "An dieses Stichwort sind keine Ideen gebunden"
    },
    placeholders: {
        start_typing: "Beginnen Sie zu tippen",
        input_text: "Geben Sie den Text ein",
        idea_heading: "Geben Sie das Titel ein",
        enter_description: "Schreiben Sie hier eine Beschreibung",
        enter_title: "Geben Sie einen Namen ein",
        enter_preview: "Geben Sie eine Vorschau ein",
        enter_category_title: "Kategorienamen eingeben",
        enter_category_description: "Kategoriebeschreibung eingeben",
        enter_category_parent: "Wählen Sie eine übergeordnete Kategorie"
    },

    common: {
        action: "Handlung",
        create: "Erstellen",
        created: "Erstellungsdatum",
        delete: "Löschen",
        deleting: "Löschung",
        edit: "Bearbeiten",
        load_more: "Mehr laden",
        ok: "Ok",
        cancel: "Abbrechen",
        confirm: "Sind Sie sicher, dass Sie löschen möchten?",
        progress: "Fortschritt",
        publish: "Veröffentlichen",
        save: "Speichern",
        show_more: "Zeig mehr",
        title: "Titel",
        tag: "Stichwort",
        updated: "Aktualisierungsdatum"
    },
    category: {
        categories: "Kategorien",
        category: "Kategorie",
        confirm: "Möchten Sie die Kategorie wirklich löschen?",
        create: "Erstellen eine Kategorie",
        delete: "Löschen eine Kategorie",
        edit: "Bearbeiten eine Kategorie",
        choose_parent: "Wählen Sie eine übergeordnete Kategorie aus",
        no_ideas: "Diese Kategorie enthält keine Ideen",
        no_category: "Keine Kategorie",
        no_found: "Kategorie nicht gefunden",
        name: "Geben Sie den Namen der Kategorie ein",
        parent: "Elternkategorie",
        description: "Kategoriebeschreibung",
        has_no_ideas: "Keine Ideen in dieser Kategorie gefunden"
    },
    idea: {
        author: "Autor",
        category: "Wählen Sie eine Kategorie aus",
        confirm: "Sind Sie sicher, dass Sie die Idee löschen möchten?",
        create: "Erstellen eine Idee",
        comments: "Kommentare",
        delete: "Eine Idee löschen",
        edit: "Eine Idee bearbeiten",
        have_an_idea: "Habe eine Idee",
        heading: "Titel",
        ideas: "Ideen",
        like: "Gefällt mir",
        likes: "Likes",
        unlike: "Gefällt mir nicht",
        status: "Status",
        tags: "Stichwörter",
        title: "Ideenname",
        updated: "Aktualisierungsdatum",
        preview: "Vorschau",
        switch: {
            public: "Öffentliche Idee",
            anon: "Anonyme Idee"
        },
        anon: "Anonym",
    },
    favourites: {
        favourites: "Favoriten",
        empty: "Die Favoritenliste ist leer",
        pages: "Seiten"
    },
    notifies: {
        comment_success: "Du hast einen Kommentar hinzugefügt!",
        comment_fail: "Kommentar wurde nicht hinzugefügt",
        idea_created: "Sie haben eine Idee erstellt!",
        idea_create_failed: "Idee konnte nicht erstellt werden",
        idea_edited: "Idee wurde bearbeitet!",
        idea_edit_failed: "Idee konnte nicht bearbeitet werden",
        category_created: "Sie haben eine Kategorie erstellt!",
        category_create_failed: "Fehler beim Erstellen der Kategorie",
        category_edited: "Kategorie wurde bearbeitet!",
        category_edit_failed: "Kategorie konnte nicht bearbeitet werden",
        title_empty_index_page: "Sei der erste, der eine Idee in diesem Raum teilt",
        title_empty_categories: "Es wurden noch keine Kategorien erstellt"
    },
    froala: {
        content_placeholder: "Geben Sie hier einen Text ein",
        text: {
            code: "Code",
            highlighted: "Beleuchtet",
            transparent: "Transparent"
        },
        paragraph: {
            grey: "Grau",
            bordered: "Umrandet",
            spaced: "Abstand",
            uppercase: "Großbuchstaben"
        },
        link_styles: {
            green: "Grün",
            strong: "Fett"
        }
    },
};
